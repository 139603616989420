const selectors = {
    mobileButton: '.js--mobile-button',
    slideMenu: '.js--slide-menu',
    slideMenuClose: '.js--slide-close',
};

class Header {
    constructor (HeaderSelector = '.js--header') {
        // Header scripts here
        this.header = document.querySelector(HeaderSelector);
        if(!this.header) {
            return;
        }
        this.scrollFunction = this.throttleScroll(this.handleScroll.bind(this),500);
        window.addEventListener('scroll', this.scrollFunction);        
        this.handleScroll();

        this.setButtonsEvents();
    }

    throttleScroll(func, timeFrame) {
        var lastTime = 0;
        return function () {
            var now = new Date();
            if (now - lastTime >= timeFrame) {
                func();
                lastTime = now;
            }
        };
    }

    setButtonsEvents() {
        let mobileButton = this.header.querySelector(selectors.mobileButton);
        if(mobileButton) {
            mobileButton.addEventListener('click',this.handleMenuButton);
        }
        let slideCloseButton = document.querySelector(selectors.slideMenuClose);
        if(slideCloseButton) {
            slideCloseButton.addEventListener('click',this.handleSlideMenuCloseButton);
        }

        const items = document.querySelectorAll(selectors.slideMenu + ' .slide-menu > .menu-item-has-children > a');
        items.forEach(item => {
            item.addEventListener('click', this.handleSlideMenuSlideDown);
        });
    }

    handleScroll() {
        if(window.scrollY > 0) {
            this.header.classList.add('active');
        } else {
            this.header.classList.remove('active');
        }
    }

    handleMenuButton() {
        const slideMenu = document.querySelector(selectors.slideMenu);
        if(!slideMenu) {
            return;
        }
        slideMenu.classList.toggle('active');
    }

    handleSlideMenuCloseButton() {
        const slideMenu = document.querySelector(selectors.slideMenu);
        if(!slideMenu) {
            return;
        }
        slideMenu.classList.remove('active');
    }

    handleSlideMenuSlideDown(e) {
        e.preventDefault();
        this.parentElement.classList.toggle('active');
    }
}

export default Header;