const itemsToHide = ['.js--slide-menu'];

class HideOnOutside {
    constructor() {
        this.hideClickedOutside();
    }

    hideClickedOutside() {
        document.addEventListener('mouseup', (e) => {
            if(e.target.classList.contains('js--mobile-button') || e.target.parentElement.classList.contains('js--mobile-button')) {
                return;
            }

            itemsToHide.forEach(item => {
                const items = document.querySelectorAll(item);
                let matched = 0;
                items.forEach(element => {
                    if(Object.is(element, e.target) || element.contains(e.target)) {
                        matched++;
                    }
                });
                if(matched === 0) {
                    items.forEach(element => {
                        if(element.classList.contains('js--hide-on-outside')) {
                            element.classList.remove('active');
                        }
                    });
                }
            });
        });
    }
}

export default HideOnOutside;