
/**
 * Element matches polyfill
 */

if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function(s) {
            let matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {}
            return i > -1;
        };
}

/**
 * Event polyfill
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */
;(function() {
    if (typeof window.CustomEvent === "function") return false
  
    function CustomEvent(event, Params = {}) {
        let params = Object.assign({}, { bubbles: false, cancelable: false, detail: undefined }, Params);
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }
  
    CustomEvent.prototype = window.Event.prototype
  
    window.CustomEvent = CustomEvent
})()